document.addEventListener('DOMContentLoaded', function () {
    (function (window, document) {

        document.querySelectorAll('[data-bs-modal-hash]').forEach(function (link) {
            let hashTarget = link.getAttribute('data-bs-target');
            let hashType = link.getAttribute('data-bs-toggle'); // offcanvas, modal, tab
            let hashId = hashTarget.substring(1);

            // Open - Add hash to History when modal/offcanvas/tab is opened
            document.getElementById(hashId).addEventListener('show.bs.' + hashType, function (event) {
                history.pushState(null, '', window.location.pathname + '#' + hashId + window.location.search);
            });

            // Close - Remove Hash when modal/offcanvas/tab is closed
            document.getElementById(hashId).addEventListener('hidden.bs.' + hashType, function () {
                if (window.location.hash === '#' + hashId) {
                    history.pushState(null, '', window.location.pathname + window.location.search);
                }
            });

            // OnInit : Open Modal/Offcanvas/Tab
            if (window.location.hash === '#' + hashId) {
                let instance;
                if (hashType === 'modal') {
                    instance = window.Modal.getOrCreateInstance(document.getElementById(hashId));
                } else if (hashType === 'offcanvas') {
                    instance = window.Offcanvas.getOrCreateInstance(document.getElementById(hashId));
                } else if (hashType === 'tab') {
                    instance = new window.Tab(document.getElementById(hashId));
                }
                if (instance) {
                    instance.show();
                }
            }

            // OnChange : Simulate back/next from browser navigation
            window.addEventListener('hashchange', function () {
                let instance;
                if (hashType === 'modal') {
                    instance = window.Modal.getOrCreateInstance(document.getElementById(hashId));
                } else if (hashType === 'offcanvas') {
                    instance = window.Offcanvas.getOrCreateInstance(document.getElementById(hashId));
                } else if (hashType === 'tab') {
                    instance = new window.Tab(document.getElementById(hashId));
                }

                if (window.location.hash === '#' + hashId) {
                    if (instance) {
                        instance.show();
                    }
                } else {
                    if (instance) {
                        instance.hide();
                    }
                }
            });

        });

    })(window, document);
});
