document.addEventListener('DOMContentLoaded', function() {
    (function (window, document) {

        // Tracking links
        document.querySelectorAll('[data-matomo-event]').forEach(function (link) {
            link.addEventListener('click', function(event) {
                let link_category = link.dataset.matomoEvent?.trim();
                let link_action = link.dataset.matomoAction?.trim();
                let link_name = link.dataset.matomoName?.trim();

                if (link_category && link_action) {
                    _paq.push(['trackEvent', link_category, link_action, link_name || '']);
                    console.log("Matomo event logged:", link_category, link_action, link_name);

                    if (link_category == "Contact") {
                        gtag('event', 'conversion', {
                          'send_to': 'AW-16857800988/jEeuCPr0o5oaEJzCtuY-',
                          'value': 1.0,
                          'currency': 'EUR'
                        });
                        console.log("Google Adwords: Contact conversion");
                    }

                } else {
                    console.error("Matomo event error: category and action must not be empty.");
                }

            });
        }); // document.querySelectorAll('[data-matomo-event]')

        // Tracking modals Bootstrap
        document.querySelectorAll('.modal').forEach(function(modal) {
            modal.addEventListener('shown.bs.modal', function(event) {
                let modalName = modal.dataset.matomoName || modal.id || 'Modal Inconnu';
                _paq.push(['trackEvent', 'Modal', 'Opening', modalName]);
                console.log("Matomo event logged: Modal Opening -", modalName);
            });
        });

        // Tracking offcanvas Bootstrap
        document.querySelectorAll('.offcanvas').forEach(function(offcanvas) {
            offcanvas.addEventListener('shown.bs.offcanvas', function(event) {
                let offcanvasName = offcanvas.dataset.matomoName || offcanvas.id || 'Offcanvas Inconnu';
                _paq.push(['trackEvent', 'Offcanvas', 'Opening', offcanvasName]);
                console.log("Matomo event logged: Offcanvas Opening -", offcanvasName);
            });
        });

    })(window, document);
});
