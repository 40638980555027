//
// Signature Animate
//

import Vivus from 'vivus';

document.addEventListener('DOMContentLoaded', function() {
    (function (window, document) {

        let borderRight, borderLeft, signatureAnimate;

        // Init
        if( document.querySelector('#brand-border-right') ) {
            borderRight = new Vivus('brand-border-right', {
                type: 'oneByOne',
                duration: 40,
                animTimingFunction: Vivus.EASE_OUT,
                start: 'manual'
            });
        }

        if( document.querySelector('#brand-border-left') ) {
            borderLeft = new Vivus('brand-border-left', {
                type: 'oneByOne',
                duration: 80,
                animTimingFunction: Vivus.EASE_OUT,
                start: 'manual'
            });
        }

        if( document.querySelector('#signatureAnimate') ) {
            signatureAnimate = new Vivus('signatureAnimate', {
                type: 'oneByOne',
                duration: 60,
                animTimingFunction: Vivus.EASE_OUT,
                start: 'manual'
            });
        }

        // Run
        if( borderRight ) {
            borderRight.play();
        }

        if( borderLeft ) {
            setTimeout(() => {
                borderLeft.play();
            }, 80);
        }

        if( signatureAnimate ) {
            setTimeout(() => {
                signatureAnimate.play();
            }, 400);
        }

    })(window, document);
});
