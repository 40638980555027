
//
// Animations
//
const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
    if (entry.isIntersecting) {
        const delay = entry.target.getAttribute('data-delay') || 0;
        setTimeout(() => {
            entry.target.classList.add('reveal');
        }, delay);
    }
    });
}, { threshold: 0.1 });

// Flashpassion markup
const sections = document.querySelectorAll('.animup');

sections.forEach(section => {
    observer.observe(section);
});
