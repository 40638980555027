document.addEventListener('DOMContentLoaded', function () {
    (function (window, document) {

        document.querySelectorAll('.box-encode').forEach(link => {
            let emailRevealed = false;
            const encodedEmail = link.getAttribute('data-encode');
            const decodedEmail = atob(encodedEmail);

            // Display
            if (!emailRevealed) {

                link.innerHTML = `
                    <span>${decodedEmail}</span>
                `;
                emailRevealed = true;
            }
        });

        document.querySelectorAll('.link-encode').forEach(link => {
            let emailRevealed = false;
            const encodedEmail = link.getAttribute('data-encode');
            const decodedEmail = atob(encodedEmail);
            const actionEmail = link.getAttribute('data-action');

            // Display
            if (!emailRevealed) {

                link.classList.remove('btn-outline-secondary');
                link.classList.add('btn-secondary');

                emailRevealed = true;
            }

            link.addEventListener('click', function () {

                // Open App Mail
                if( actionEmail == 'openmail' ){
                    window.location.href = `mailto:${decodedEmail}`;
                }

                // Copy-paste
                if( actionEmail == 'copypaste' ){
                    navigator.clipboard.writeText(decodedEmail);
                }

            });
        });

    })(window, document);
});
