document.addEventListener('DOMContentLoaded', function() {
    (function (window, document) {

        // Fonction pour trier les badges
        function sortBadges(attribute, order = 'asc') {
            const skillsCloud = document.querySelector('.skills-cloud');
            const badges = Array.from(skillsCloud.querySelectorAll('.badge'));

            badges.sort((a, b) => {
                const aValue = parseFloat(a.getAttribute(attribute));
                const bValue = parseFloat(b.getAttribute(attribute));

                if (order === 'asc') {
                    return aValue - bValue;
                } else {
                    return bValue - aValue;
                }
            });

            // Vider le contenu de skills-cloud
            skillsCloud.innerHTML = '';

            // Réinsérer les badges triés
            badges.forEach(badge => {
                skillsCloud.appendChild(badge);
            });
        }

        // Exemple d'utilisation : trier par data-like en ordre décroissant
        //sortBadges('data-like', 'asc');

        // Vous pouvez appeler sortBadges avec d'autres attributs et ordres :
        // sortBadges('data-expert', 'asc');
        // sortBadges('data-year', 'desc');

    })(window, document);
});
